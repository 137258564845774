@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --header-height: 50px;
    --header-mb:2rem;
    --header-position:relative;
    --header-inner-position:relative
  }
img:hover{
  transition: ease .4s;
  transform: scale(1.2)  ;
}
img{
  transition: ease .4s;
  object-position: 50% 0%;
}
#main-article p,#main-article ul, #main-article ol{
    margin-bottom: 25px;
}
#main-article li{
  margin-bottom: 10px;
}

@layer base{
  body{
    font-family: Inter, 'Inter var',ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    white-space: revert;
    overflow-x: hidden;
    text-rendering: optimizeSpeed;
   }
}

:root {
  --clr-bg-dark: #1f2937; /* gray-800 */
  --clr-bg-light: #fafafa; /* zinc-50 */

  --clr-text-dark: #1f2937; /* gray-200 */
  --clr-text-light: #fafafa; /* zinc-50 */
}

.dark {
  background-color: var(--clr-bg-dark);
  color: var(--clr-text-dark);
}

.light {
  background-color: var(--clr-bg-light);
  color: var(--clr-text-light);
}
