.VIpgJd-ZVi9od-l4eHX-hSRGPd,.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  /*display: none;*/
}

iframe#\:1\.container {
  display: none;
}
body{
  top:0px !important
}
